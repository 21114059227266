import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../components/input';

import { FIELDS } from '../../../bi/constants/trips';

import style from '../styles/form.module.scss';

const LABELS = {
  NAME_DESCRIPTION: 'Описание изменения',
  DESCRIPTION: 'Наименование изменения',
};

const EditHistory = ({
  isEditing,
  description,
  validation,
  onChangeDescription,
  validationHistory,
}) => isEditing && (
  <div className={ `${style.row} ${style.panel}` }>
    <h4>{ LABELS.NAME_DESCRIPTION }</h4>
    <div className={ style.row } >
      <Input
        field={ FIELDS.DESCRIPTION }
        value={ description }
        label={ LABELS.DESCRIPTION }
        onChange={ onChangeDescription }
        onBlur={ validationHistory }
        valid={ validation.Description }
      />
    </div>
  </div>
);

EditHistory.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  description: PropTypes.string,
  validation: PropTypes.object.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  validationHistory: PropTypes.func.isRequired,
};

EditHistory.defaultProps = {
  description: '',
};

export default EditHistory;
