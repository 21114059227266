import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import AjaxButton from '../../../../components/ajaxButton';

import { LOCALE } from '../../../../bi/constants/hotels';

import styles from '../../styles/trip.module.scss';

const LABELS = {
  SEND_RU: 'Скачать RU',
  SEND_EN: 'Скачать EN',
  CANCEL: 'Отмена',
};

const DownloadDialog = ({
  title,
  onDownload,
  loading,
  onClose,
}) => (
  <Dialog onClick={ onClose }>
    <div className={ styles['user-dialog'] }>
      <p>{ title }</p>
      <div>
        <div className={ `${styles.dialog_buttons}` }>
          <AjaxButton
            label={ LABELS.SEND_RU }
            loading={ loading }
            onClick={ () => onDownload(LOCALE.RU) }
          />
          <AjaxButton
            label={ LABELS.SEND_EN }
            loading={ loading }
            onClick={ () => onDownload(LOCALE.EN) }
          />
        </div>
        <Button
          label={ LABELS.CANCEL }
          theme='flat'
          onClick={ onClose }
        />
      </div>
    </div>
  </Dialog>
);

DownloadDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { DownloadDialog };
